/*
|--------------------------------------------------------------------------
! All Your Application Navigations Paths Will Be Here
|--------------------------------------------------------------------------
*/

/**
 * @readonly
 * ! PUBLIC ROUTES
 */
export const PUBLIC_PATH = "/";
export const LOGIN_PATH = "/";
export const REGISTER_PATH = "signup";
export const FORGETPASSWORD = "/auth/forgetPassword";
export const RESETPASSWORD = "/aut/resetPassword";
export const AFTERREGISTER = "/verificationMail";

/**
 * @readonly
 * ! PROTECTED ROUTES
 */
export const ACCEUIL_PATH = "/";
export const PRODUCTS_PATH = "/dossier-client";
export const CLIENTS_PATH = "/clients";

// DOSSIER CLEINT
export const DOSSIER_PATH = "/client/:clientID";
export const SOLDES = "";
export const CLIENT = "client/";
export const DETAIL_CLIENTS_PATH = ":id/";
export const DEPENSE = "depenses/";
export const ADD_DEPENSE = "add-depense/";
export const DETAIL_DEPENSE = "detailDepense/:id";
// VENTE
export const VENTE = "ventes/";
export const ADD_VENTE = "add-vente/";
export const DETAIL_VENTE = ":id/";

// PRODUCT
export const PRODUCT = "products/";
export const ADD_PRODUCT = "add-product/";
export const DETAIL_PRODUCT = ":id/";

// TRANSACTIONS
export const TRANSACTION = "transactions/";
// TRANSACTIONS
export const BALANCE = "balance/";
export const BALANCE_GENERAL = "balance/generale/";
export const GENERALE = "generale/";
export const BALANCE_AUXILIAIRE = "balance/auxiliaire/";
export const AUXILIAIRE = "auxiliaire/";
export const BALANCE_AGEE = "balance/agee/";
export const AGEE = "agee/";
export const BILAN = "bilan-comptable/";
// compte de résultats
export const COMPTE_RESULTATS = "compte-resultats/";

export const TVA = "tva/";

// fournisseur
export const FOURNISSEURS = "fournisseurs/";
export const DETAIL_FOURNISSEURS_PATH = ":id/";
//parametre
export const PARAMETRE_PATH = "parametre/";

export const FACTURE = "facture/";

//CABINET
export const COLLABORATEURS = "/gestion_utilisateurs/";
export const PARAMETRES_CABINET = "/parametres_cabinet/";
export const ROLE_AND_PERMISSIONS = "/role_and_permissions/";
export const ADD_ROLE = "nouveau_role/";
export const DETAIL_COLLABORATEURS_PATH = ":id/";
export const GENERATE_ETATS = "generate-etats/";
export const SAISIE_MANUELLE = "saisie-manuelle/";
export const SAGE = "sage/";
export const GESTION_TIERS = "gestion-tiers/";
export const IMPORT_FICHIERS = "import-fichers/";

export const FOURNISSEURS_CABINET = "fournisseurs/";
export const DETAIL_FOURNISSEURS_PATH_CABINET = ":id/";
export const CLIENT_CABINET = "client/";
export const DETAIL_CLIENTS_PATH_CABINET = ":id/";
export const SALARIES_CABINET = "salaries/";
export const DETAIL_SALARIES_PATH_CABINET = ":id/";

//SAGE
//**************************CODE JOURNAL********************** */
export const CODE_JOURNAL = "/code_journaux";
export const DETAIL_CODE_JOURNAL_PATH = ":id/";

// journal_achat
export const JOURNAL_ACHAT = "journal_achat/";
export const JOURNAL_ACHAT_PATH = ":id/";
//export const SAISIE_PATH = "journal_achat/saisie/";
export const SAISIE_PATH = "comptabilite/saisie/";
export const DISPLAYJOURNAL_PATH = "journal_achat/display_journal/";

export const GRAND_LIVRE = "grand_livre/";
export const BALANCE_GENERALE = "balance/";

export const PARAMETRES_DOSSIER = "parametres_dossier/";

// Comptabilite
export const COMPTABILITE = "comptabilite/";

// export const ACTIF_PATH = "/dossier-actif";
export const DESACTIVES_PATH = "/dossier-desactives";
export const ARCHIVE_PATH = "/dossier-archives";

/**
 * @readonly
 * ! NOT FOUND ROUTE
 */
export const NOTFOUND_PATH = "*";

export const DEFAULT_API = process.env.REACT_APP_API_URL;
export const DEFAULT_API_IMAGE = process.env.REACT_APP_API_IMG_URL;

// USER ROUTE
export const ACTIVATION_API_ROUTE = `${DEFAULT_API}/v1/users/activation/`;
export const LOGIN_API_ROUTE = `${DEFAULT_API}/v1/auth/login/`;
export const REGISTER_API_ROUTE = `${DEFAULT_API}/v1/users/`;
export const UPDATE_USER_DATA = `${DEFAULT_API}/v1/users/`;
export const GET_USER_DATA = `${DEFAULT_API}/v1/users/`;
export const GET_USER_BY_ID_DATA = `${DEFAULT_API}/v1/users/<int:id>`;
export const REFRESH_TOKEN = `${DEFAULT_API}/v1/auth/refresh/`;
export const GET_USER_API_ROUTE = `${DEFAULT_API}/v1/users/<int:id>`;
export const GET_USER_COLLABORATEUR_API_ROUTE = `${DEFAULT_API}/v1/user_collaborateur`;
// DOSSIER CLIENT ROUTE
export const ADD_DOSSIER_CLIENT_API_ROUTE = `${DEFAULT_API}/v1/dossiers-client/`;
export const GET_DOSSIER_CLIENT_API_ROUTE = `${DEFAULT_API}/v1/dossiers-client/`;
// export const GET_ARCHIVED_DOSSIER_CLIENT_API_ROUTE = `${DEFAULT_API}/v1/archive-dossiers-client/`
export const UPDATE_DOSSIER_CLIENT_API_ROUTE = `${DEFAULT_API}/v1/dossiers-client/`;
export const DELETE_DOSSIER_CLIENT_API_ROUTE = `${DEFAULT_API}/v1/dossiers-client/`;
// Calculate the compte des resultats (Incomes statements)
export const GET_THE_COMPTE_RESULTATS_API_ROUTE = `${DEFAULT_API}/v1/compte-resultat/`;

//clients
export const ADD_CLIENT_API_ROUTE = `${DEFAULT_API}/v1/clients/`;
export const GET_CLIENT_API_ROUTE = `${DEFAULT_API}/v1/clients/`;
export const UPDATE_CLIENT_API_ROUTE = `${DEFAULT_API}/v1/clients/`;
export const DELETE_CLIENT_API_ROUTE = `${DEFAULT_API}/v1/clients/`;

// export const GET_CLIENT_API_ROUTE = `${DEFAULT_API}/v1/clients/`
//fournisseurs
export const ADD_FOURNISSEUR_API_ROUTE = `${DEFAULT_API}/v1/fournisseurs/`;
export const GET_FOURNISSEUR_API_ROUTE = `${DEFAULT_API}/v1/fournisseurs/`;
export const UPDATE_FOURNISSEUR_API_ROUTE = `${DEFAULT_API}/v1/fournisseurs/`;
export const DELETE_FOURNISSEUR_API_ROUTE = `${DEFAULT_API}/v1/fournisseurs/`;

// Depenses
export const ADD_DEPENSES_API_ROUTE = `${DEFAULT_API}/v1/sorties/`;
export const GET_DEPENSES_API_ROUTE = `${DEFAULT_API}/v1/sorties/`;
export const UPDATE_DEPENSES_API_ROUTE = `${DEFAULT_API}/v1/sorties/`;
export const DELETE_DEPENSES_API_ROUTE = `${DEFAULT_API}/v1/sorties/`;
// vente
export const ADD_VENTE_API_ROUTE = `${DEFAULT_API}/v1/entrees/`;
export const GET_VENTE_API_ROUTE = `${DEFAULT_API}/v1/entrees/`;
export const UPDATE_VENTE_API_ROUTE = `${DEFAULT_API}/v1/entrees/`;
export const DELETE_VENTE_API_ROUTE = `${DEFAULT_API}/v1/entrees/`;
// Produits
export const ADD_PRODUITS_API_ROUTE = `${DEFAULT_API}/v1/product/`;
export const GET_PRODUITS_API_ROUTE = `${DEFAULT_API}/v1/product/`;
export const UPDATE_PRODUITS_API_ROUTE = `${DEFAULT_API}/v1/product/`;
export const DELETE_PRODUITS_API_ROUTE = `${DEFAULT_API}/v1/product/`;

//routes all transactions
export const GET_ALL_TRANSACTIONS_API_ROUTE = `${DEFAULT_API}/v1/entrees-sorties/`;
export const GET_ONE_TRANSACTIONS_API_ROUTE = `${DEFAULT_API}/v1/entrees-sorties/`;
export const DELETE_ONE_TRANSACTIONS_API_ROUTE = `${DEFAULT_API}/v1/entrees-sorties/`;

//ocr facture
export const GET_ONE_OCR_API_ROUTE = `${DEFAULT_API}/v1/factures/`;
export const GET_OCR_API_ROUTE = `${DEFAULT_API}/v1/factures/`;
export const ADD_OCR_API_ROUTE = `${DEFAULT_API}/v1/extract_facture/`;

// Comptes Comptables

export const GET_COMPTES_CLASS = `${DEFAULT_API}/v1/classes/`;
export const GET_COMPTES_COMPT = `${DEFAULT_API}/v1/comptes/`;
export const GET_COMPTES_COMPT_DIV = `${DEFAULT_API}/v1/comptes-divisionnaires/`;

//cabinet
//Collaborateur
export const ADD_COLLABORATEUR_API_ROUTE = `${DEFAULT_API}/v1/collaborateur/`;
export const GET_COLLABORATEUR_API_ROUTE = `${DEFAULT_API}/v1/collaborateur/`;
export const UPDATE_COLLABORATEUR_API_ROUTE = `${DEFAULT_API}/v1/collaborateur/`;
export const DELETE_COLLABORATEUR_API_ROUTE = `${DEFAULT_API}/v1/collaborateur/`;

// Roles
export const ADD_ROLE_API_ROUTE = `${DEFAULT_API}/v1/roles/`;
export const GET_ROLE_API_ROUTE = `${DEFAULT_API}/v1/roles/`;
export const ADD_PERMISSIONS_API_ROUTE = (id) =>
  `${DEFAULT_API}/v1/roles/${id}/permissions/`;
export const GET_PERMISSIONS_API_ROUTE = `${DEFAULT_API}/v1/roles/<int:id>/permissions/`;
export const UPDATE_ROLE_API_ROUTE = `${DEFAULT_API}/v1/roles/`;
export const DELETE_ROLE_API_ROUTE = `${DEFAULT_API}/v1/roles/`;

export const GET_EXPERTCOMPTABLE_DOSSIERS_API_ROUTE = `${DEFAULT_API}/v1/collaborateur/client_dossiers`;
//Facture-cabinet
export const ADD_FACTURE_CABINET_API_ROUTE = `${DEFAULT_API}/v1/facture-cabinet/`;
export const UPDATE_FACTURE_CABINET_API_ROUTE = `${DEFAULT_API}/v1/facture-cabinet/`;
export const DELETE_FACTURE_CABINET_API_ROUTE = `${DEFAULT_API}/v1/facture-cabinet/`;
export const GET_FACTURE_CABINET_API_ROUTE = `${DEFAULT_API}/v1/facture-cabinet/`;

//Entreprise
export const GET_BALANCE_GENERAL = `${DEFAULT_API}/v1/balance/`; // Balance Generale
export const GET_LIVRE_JOURNAL = `${DEFAULT_API}/v1/journal/`; // Livre Journal
export const GET_Grand_Livre = `${DEFAULT_API}/v1/grand-livre/`; // Grand livre

//Cabinet
export const GET_BALANCE_GENERAL_CABINET = `${DEFAULT_API}/v1/balance_cabinet/`; // Balance Generale
export const GET_LIVRE_JOURNAL_CABINET = `${DEFAULT_API}/v1/journal_cabinet/`; // Livre Journal
export const GET_Grand_Livre_CABINET = `${DEFAULT_API}/v1/grand-livre_cabinet/`; // Grand livre

export const GET_CODES_COMPTABLES = `${DEFAULT_API}/v1/codes-comptables/`; //Codes Comptables

export const GET_CODES = `${DEFAULT_API}/v1/codes/`;

//salaries
export const ADD_SALARIES_API_ROUTE = `${DEFAULT_API}/v1/salaries/`;
export const GET_SALARIES_API_ROUTE = `${DEFAULT_API}/v1/salaries/`;
export const UPDATE_SALARIES_API_ROUTE = `${DEFAULT_API}/v1/salaries/`;
export const DELETE_SALARIES_API_ROUTE = `${DEFAULT_API}/v1/salaries/`;

//Import file
export const CLIENT_FILE_API_ROUTE = `${DEFAULT_API}/v1/client_excel/`;
export const FOURNISSEUR_FILE_API_ROUTE = `${DEFAULT_API}/v1/fournisseurs_excel/`;
export const SALARIES_FILE_API_ROUTE = `${DEFAULT_API}/v1/salaries_excel/`;

//SAGE
//***********************CODES JOURNAUX ****************************************** */
export const ADD_CODEJOURNAUX_API_ROUTE = `${DEFAULT_API}/v1/livrejournal_spec/`;
export const GET_CODEJOURNAUX_API_ROUTE = `${DEFAULT_API}/v1/livrejournal_spec/`;
export const UPDATE_CODEJOURNAUX_API_ROUTE = `${DEFAULT_API}/v1/livrejournal_spec/`;
export const DELETE_CODEJOURNAUX_API_ROUTE = `${DEFAULT_API}/v1/livrejournal_spec/`;

//***********************LIVRES JOURNAUX ****************************************** */
export const ADD_LIVREJOURNAUX_API_ROUTE = `${DEFAULT_API}/v1/livrejournal_date/`;
export const GET_LIVREJOURNAUX_API_ROUTE = `${DEFAULT_API}/v1/livrejournal_date/`;
export const UPDATE_LIVREJOURNAUX_API_ROUTE = `${DEFAULT_API}/v1/livrejournal_date/`;
export const DELETE_LIVREJOURNAUX_API_ROUTE = `${DEFAULT_API}/v1/livrejournal_date/`;
export const ADD_LIVREJOURNAUX_PIECEJOINTE_API_ROUTE = `${DEFAULT_API}/v1/livrejournal_filemodif/`;

//***********************ECRITURES COMPTABLES ****************************************** */
export const ADD_ECRITURESCOMPTABLES_API_ROUTE = `${DEFAULT_API}/v1/ecriture_spec/`;
export const GET_ECRITURESCOMPTABLES_API_ROUTE = `${DEFAULT_API}/v1/ecriture_spec/`;
export const UPDATE_ECRITURESCOMPTABLES_API_ROUTE = `${DEFAULT_API}/v1/ecriture_spec/`;
export const DELETE_ECRITURESCOMPTABLES_API_ROUTE = `${DEFAULT_API}/v1/ecriture_spec/`;

//********************* Ecriture comptables du Livre Journal par mois *************** */
export const GET_LIVREECRITURESByMonth_API_ROUTE = `${DEFAULT_API}/v1/livrejournal_month/`;
export const GET_LIVREECRITURES_API_ROUTE = `${DEFAULT_API}/v1/livrejournal_total/`;
export const GET_TIERS_BYDOSSIER_API_ROUTE = `${DEFAULT_API}/v1/tiers/`;

export const ADD_PLAN_COMPTABLE_API_ROUTE = `${DEFAULT_API}/v1/plancomptable_bydossier/`;
export const UPDATE_PLAN_COMPTABLE_API_ROUTE = `${DEFAULT_API}/v1/plancomptable_bydossier/`;
export const DELETE_PLAN_COMPTABLE_API_ROUTE = `${DEFAULT_API}/v1/plancomptable_bydossier/`;

//******************************* COMPTABILITE ******************************** */
export const GET_GRANDLIVRE_GENERAL_API_ROUTE = `${DEFAULT_API}/v1/grandlivre_general/`;
export const GET_BALANCE_GENERAL_API_ROUTE = `${DEFAULT_API}/v1/balance_general/`;
export const GET_BALANCE_AUXILIAIRE_API_ROUTE = `${DEFAULT_API}/v1/balance_auxiliaire/`;
export const GET_BALANCE_AGEE_API_ROUTE = `${DEFAULT_API}/v1/balance_agee/`;
export const GET_BILAN_COMPTABLE_API_ROUTE = `${DEFAULT_API}/v1/bilan_comptable/`;

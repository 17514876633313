import {MdAccountBalanceWallet} from "react-icons/md";
import {
  ACCEUIL_PATH,
  ARCHIVE_PATH,
  COLLABORATEURS,
  // DESACTIVES_PATH,
  //GENERATE_ETATS,
  //GESTION_TIERS,
  //IMPORT_FICHIERS,
  PARAMETRES_CABINET,
  PRODUCTS_PATH,
  ROLE_AND_PERMISSIONS,
  //SAISIE_MANUELLE,

  //DOSSIER_PATH,
  CLIENT,
  FOURNISSEURS,
  //GESTION_TIERS,
  SALARIES_CABINET,
  JOURNAL_ACHAT,
  PARAMETRES_DOSSIER,
  GRAND_LIVRE,
  BALANCE,
  COMPTE_RESULTATS,
  COMPTABILITE,
  BILAN,
} from "./navigationPaths";
import {
  HiHome,
  HiFolderAdd,
  HiFolderOpen,
  HiOutlineCog, //paramètres
  HiUsers,
  HiOutlineDocumentSearch,
  HiCollection,
  //HiOutlinePencil,
  //HiOutlineInboxIn,
  //HiOutlineClipboardList,
  HiTruck,
  HiUserGroup,
  HiBriefcase,
  HiLibrary,
  HiNewspaper,
  HiBookOpen,
  HiDocumentReport,
  HiLockClosed,
  HiOutlineClipboardList,
} from "react-icons/hi";

/*
|--------------------------------------------------------------------------
! Sidebar Items
|--------------------------------------------------------------------------
|
| Here we can add sidebar links, paths and icons
|
*/

export const links = [
  {path: ACCEUIL_PATH, icon: HiHome, label: "Accueil"},
  {path: PRODUCTS_PATH, icon: HiFolderAdd, label: "Dossiers Clients"},
  // { path: ACTIF_PATH, icon: HiFolderOpen , label: "Dossiers actifs" },
  // {path: DESACTIVES_PATH, icon: HiFolderOpen, label: "Dossiers désactivés"},
  {path: ARCHIVE_PATH, icon: HiFolderOpen, label: "Dossiers archivés"},
];

export const links_cabinet = [
  {
    path: "/",
    icon: HiHome,
    label: "Dashboard",
    multiple: false,
  },
  {
    path: "/code_journaux",
    icon: HiLockClosed,
    label: "Code Journaux",
    multiple: false,
  },
  {
    path: PRODUCTS_PATH,
    icon: HiCollection,
    label: "Gestion des dossiers",
    multiple: true,
    sous_links: [
      {path: PRODUCTS_PATH, icon: HiFolderAdd, label: "Dossiers Clients"},
      // {path: DESACTIVES_PATH, icon: HiFolderOpen, label: "Dossiers désactivés"},
      {path: ARCHIVE_PATH, icon: HiFolderOpen, label: "Dossiers archivés"},
    ],
  },
  {
    path: COLLABORATEURS,
    icon: HiUsers,
    label: "Gestion équipe",
    multiple: true,
    sous_links: [
      {path: COLLABORATEURS, icon: HiFolderAdd, label: "Utilisateurs"},
      {
        path: ROLE_AND_PERMISSIONS,
        icon: HiFolderAdd,
        label: "Rôles et permissions",
      },
      //{path: "", icon: HiFolderAdd, label: "Rapport d'activité"},
    ],
  },
  /*{
    path: "sauvegarde",
    icon: HiOutlineDocumentSearch,
    label: "Sauvegarde/Restauration",
    multiple: false,
  },*/
  {
    path: PARAMETRES_CABINET,
    icon: HiOutlineCog,
    label: "Paramètres",
    multiple: false,
  },
  /*{ path: PRODUCTS_PATH, icon: HiFolderAdd, label: "Dossiers Clients" },
    // { path: ACTIF_PATH, icon: HiFolderOpen , label: "Dossiers actifs" },
    { path: DESACTIVES_PATH, icon: HiFolderOpen , label: "Dossiers désactivés" },
    { path: ARCHIVE_PATH, icon: HiFolderOpen , label: "Dossiers archivés" },*/
];

export const linksCabinet_dossiers = [
  {
    path: ACCEUIL_PATH,
    icon: HiHome,
    label: "Revenir à l'accueil",
    multiple: false,
  },
  {
    path: "",
    icon: HiOutlineDocumentSearch,
    label: "Tableau de Board",
    multiple: false,
  },
  {
    path: COMPTABILITE,
    icon: HiBriefcase,
    label: "COMPTABILITE",
    multiple: true,
    sous_links: [
      {
        path: COMPTABILITE,
        icon: HiBookOpen,
        label: "Saisie Ecritures ",
      },
      /*{
        path: FOURNISSEURS,
        icon: HiNewspaper ,
        label: "Grand Livres",
      },
      {
        path: SALARIES_CABINET,
        icon: HiLibrary  ,
        label: "Balances",
      },*/
    ],
  },
  {
    path: JOURNAL_ACHAT,
    icon: HiDocumentReport,
    label: "Gestion des états financiers",
    multiple: true,
    sous_links: [
      {
        path: JOURNAL_ACHAT,
        icon: HiBookOpen,
        label: "Journaux ",
      },
      {
        path: GRAND_LIVRE,
        icon: HiNewspaper,
        label: "Grand Livre",
      },
      {
        path: BALANCE,
        icon: HiLibrary,
        label: "Balance",
      },
      {
        path: BILAN,
        icon: HiOutlineClipboardList,
        label: "Bilan Comptable",
      },
      {
        path: COMPTE_RESULTATS,
        icon: MdAccountBalanceWallet,
        label: "Compte de résultat",
      },
      /*{
        path: FOURNISSEURS,
        icon: HiNewspaper ,
        label: "Grand Livres",
      },
      {
        path: SALARIES_CABINET,
        icon: HiLibrary  ,
        label: "Balances",
      },*/
    ],
  },
  /*{
    path: SAISIE_MANUELLE,
    icon: HiOutlinePencil,
    label: "Saisie manuelle",
    multiple: false,
  },
  {
    path: GENERATE_ETATS,
    icon: HiOutlineClipboardList,
    label: "Génération des états",
    multiple: false,
  },*/
  {
    path: CLIENT,
    icon: HiUsers,
    label: "Gestion des Tiers",
    multiple: true,
    sous_links: [
      {
        path: CLIENT,
        icon: HiUserGroup,
        label: "Clients",
      },
      {
        path: FOURNISSEURS,
        icon: HiTruck,
        label: "Fournisseurs",
      },
      {
        path: SALARIES_CABINET,
        icon: HiBriefcase,
        label: "Salariés",
      },
    ],
  },
  {
    path: PARAMETRES_DOSSIER,
    icon: HiOutlineCog,
    label: "Paramètres",
    multiple: false,
  },
];
